import {
  AutoAwesome,
  AutoAwesomeMotionTwoTone,
  BrokenImage,
  CancelOutlined,
  CheckCircle,
  Newspaper,
  PauseCircle,
  PlayCircle,
  Psychology,
  Start
} from '@mui/icons-material';
import { Searchbar } from '../presets';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useSetRecoilState } from 'recoil';
import { settingsTabStateAtom } from './atom';
import { useNavigate } from 'react-router-dom';

const {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Stack,
  IconButton,
  Button,
  Divider,
  DialogActions,
  CircularProgress,
  Typography
} = require('@mui/material');
const { useEffect, useState } = require('react');

// {"openai":{"status":"error","message":"OpenAI key not assigned","code":"key_not_assigned"},"midjourney":{"status":"error","message":"Error fetching settings"},"wordpress":{"status":"success"},"hasErrors":true}
const ErrorDetails = ({ status }) => {
  return (
    <Stack width="100%" padding="0 12px">
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        // border="1px solid #ccc"
        padding="5px"
      >
        <Stack
          style={{
            width: '100%',
            boxShadow: '0 0 5px rgba(0,0,0,0.1)',
            padding: '10px',
            flexGrow: 1,
            maxHeight: '200px'
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Psychology />
            <Typography variant="body2">Openai</Typography>
          </Stack>
          <p style={{ color: status.openai.status === 'error' ? 'red' : 'green' }}>
            {status.openai.message}
          </p>
        </Stack>

        <Stack
          style={{
            width: '100%',
            boxShadow: '0 0 5px rgba(0,0,0,0.1)',
            padding: '10px',
            flexGrow: 1,
            maxHeight: '200px'
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <BrokenImage />
            <Typography variant="body2">Midjourney</Typography>
          </Stack>
          <p style={{ color: status.midjourney.status === 'error' ? 'red' : 'green' }}>
            {status.midjourney.message}
          </p>
        </Stack>
        <Stack
          style={{
            width: '100%',
            boxShadow: '0 0 5px rgba(0,0,0,0.1)',
            padding: '10px',
            maxHeight: '200px',
            flexGrow: 1
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Newspaper />
            <Typography variant="body2">Wordpress</Typography>
          </Stack>
          <p style={{ color: status.wordpress.status === 'error' ? 'red' : 'green' }}>
            {status.wordpress.message}
          </p>
        </Stack>
      </Stack>
    </Stack>
  );
};

const result_cache = {};

const SiteDiagnoseRow = ({ site, updateSite, pushAnotherSite }) => {
  const [status, setStatus] = useState({});
  const setDefaultTab = useSetRecoilState(settingsTabStateAtom);

  useEffect(() => {
    if (site.status === 'pending') {
      diagnoseSite();
    }
  }, [site.status]);

  const diagnoseSite = async () => {
    try {
      const fn = httpsCallable(getFunctions(), 'wordpressRequest');

      const { data } = await fn({
        siteId: site.id,
        openai_checked: !!result_cache[site.openai_key],
        midjourney_checked: !!result_cache[site.selected_dc_cred],
        command: 'diagnoseSite'
      });

      if (!!result_cache[site.openai_key]) {
        data.openai = result_cache[site.openai_key];
      }

      if (result_cache[site.selected_dc_cred]) {
        data.midjourney = result_cache[site.selected_dc_cred];
      }

      data.hasErrors = Object.keys(data).some((key) => data[key].status === 'error');

      setStatus(data);
      result_cache[site.openai_key] = data.openai;
      result_cache[site.selected_dc_cred] = data.midjourney;
      updateSite({ ...site, status: 'completed' });
      setTimeout(pushAnotherSite, 2500);
    } catch (e) {
      updateSite({ ...site, status: 'temp' });
      setTimeout(() => {
        updateSite({ ...site, status: 'failed' });
        pushAnotherSite();
      }, 1500);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        padding: '10px 0',
        border: '1px solid #ccc',
        borderRadius: '5px',
        margin: '5px 0'
      }}
    >
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        paddingRight={2}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={site.selected}
              onChange={(e) => updateSite({ ...site, selected: e.target.checked })}
            />
          }
          label={site.url}
        />
        {site.status === 'idle' && 'Not Started'}

        {site.status === 'pending' && <CircularProgress size={20} />}

        {site.status === 'failed' && <label style={{ color: 'red' }}>Failed</label>}

        {site.status === 'completed' &&
          (status.hasErrors ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                size="small"
                startIcon={<AutoAwesome />}
                color="primary"
                onClick={() => {
                  setDefaultTab('Credentials');

                  window.open('/wordpress_sites/' + site.id, '_blank');
                }}
              >
                fix
              </Button>
              <CancelOutlined color="error" />
            </Stack>
          ) : (
            <CheckCircle color="success" />
          ))}
      </Stack>

      {status.hasErrors && <ErrorDetails status={status} />}
    </div>
  );
};

const DiagnosingDialog = ({ sites, open, setOpen }) => {
  const [data, setData] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (sites) {
      setData(
        sites.map((site) => {
          return {
            id: site.id,
            url: site.url,
            openai_key: site.settings.openai_key,
            selected_dc_cred: site.settings.selected_dc_cred,
            selected: true,
            status: 'idle'
          };
        })
      );
    }
  }, [sites]);

  useEffect(() => {
    if (selectAll) {
      setData(
        data.map((site) => {
          return {
            ...site,
            selected: true
          };
        })
      );
    } else {
      setData(
        data.map((site) => {
          return {
            ...site,
            selected: false
          };
        })
      );
    }
  }, [selectAll]);

  const pushAnotherSite = () => {
    if (!isRunning) {
      return;
    }

    setData((p) => {
      let data = [...p];

      for (let i = 0; i < data.length; i++) {
        if (data[i].selected && (data[i].status === 'idle' || data[i].status === 'failed')) {
          data[i].status = 'pending';
          break;
        }
      }
      return data;
    });
  };

  const startProcess = () => {
    if (isRunning) {
      setIsRunning(false);
      return;
    }
    setIsRunning(true);

    let olData = [...data];
    let qs = 0;
    const QSIZE = 2;
    for (let i = 0; i < olData.length && qs < QSIZE; i++) {
      if (olData[i].selected && (olData[i].status === 'idle' || olData[i].status === 'failed')) {
        olData[i].status = 'pending';
        qs++;
      }
    }

    setData(olData);
  };

  //   console.log(data);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Stack spacing={2} justifyContent="space-between" alignItems="center" direction="row">
          <FormControlLabel
            control={
              <Checkbox checked={selectAll} onChange={(e) => setSelectAll(e.target.checked)} />
            }
            label="Select All"
          />
          <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
            <Searchbar setSearch={setSearch} />
          </Stack>
        </Stack>
        <Divider sx={{ marginTop: '10px', borderWidth: '1px' }} />

        {data
          .filter((site) => site.url.toLowerCase().includes(search))
          .map((site, index) => (
            <SiteDiagnoseRow
              key={site.id}
              site={site}
              updateSite={(newData) => {
                setData((p) => {
                  let data = [...p];
                  data[index] = newData;
                  return data;
                });
              }}
              pushAnotherSite={pushAnotherSite}
            />
          ))}
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={startProcess}
          startIcon={isRunning ? <PauseCircle color="error" /> : <PlayCircle color="primary" />}
        >
          {isRunning ? 'Pause' : 'Diagnose Selected Sites'}
        </Button>

        <Button variant="outlined" onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiagnosingDialog;
