import { Spa } from '@mui/icons-material';
import {
  Card,
  CircularProgress,
  Fab,
  Grid,
  Icon,
  Stack,
  lighten,
  styled,
  useTheme
} from '@mui/material';
import { H2, H5 } from 'app/components/Typography';
import useAuth from 'app/hooks/useAuth';
import { collection, doc, getDocs, getFirestore, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';

const ContentBox = styled('div')(() => ({
  display: 'flex',

  alignItems: 'center'
}));

const FabIcon = styled(Fab)(() => ({
  width: '44px !important',
  height: '44px !important',
  boxShadow: 'none !important'
}));

const H3 = styled('h3')(({ textcolor }) => ({
  margin: 0,
  color: textcolor,
  fontWeight: '500',
  marginLeft: '12px'
}));

const H1 = styled('h1')(({ theme }) => ({
  margin: 0,
  flexGrow: 1,
  color: theme.palette.text.secondary
}));

const Span = styled('span')(({ textcolor }) => ({
  fontSize: '13px',
  color: textcolor,
  marginLeft: '4px'
}));

const IconBox = styled('div')(() => ({
  width: 16,
  height: 16,
  color: '#fff',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '300px ',
  justifyContent: 'center',
  '& .icon': { fontSize: '14px' }
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '0.70rem',
  color: theme.palette.text.secondary
}));

const isToday = (someDateMilli) => {
  if (!someDateMilli) return true;

  const someDate = new Date(someDateMilli);
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};
const getStatData = (user) => {
  const date_current = new Date();
  const obj = {
    image_generated: 0,
    post_processed: 0,
    image_generated_date: 0,
    post_processed_date: 0,
    last_image_generated: 0,
    post_in_queue: 0
  };

  if (user.acc.usage) {
    obj.image_generated = user.acc.usage.totalCount || 0;
    obj.image_generated_date = user.acc.usage.lastGen || 0;
    obj.last_image_generated = user.acc.usage.lastGen || 0;
  }

  if (user.acc.wp_usage) {
    obj.post_processed = user.acc.wp_usage.count || 0;
    obj.post_processed_date = user.acc.wp_usage.date || 0;
    obj.post_in_queue = user.acc.wp_usage.wp_task_in_queue || -1;
  }

  return obj;
};

//info will be updated every 6 hours
const SiteInfo = ({ site }) => {
  const [without_image, setWithoutImage] = useState(null);

  useEffect(() => {
    if (site.lastStatUpdate && Date.now() - site.lastStatUpdate < 1000 * 60 * 60 * 6) {
      setWithoutImage(site.without_image);
    } else {
      const getWithoutImage = async () => {
        const fn = httpsCallable(getFunctions(), 'wordpressRequest');
        console.log(site);
        const { data } = await fn({
          site: site,
          command: 'countPostsWithoutImage'
        });

        // console.log(data);
        setWithoutImage(data);
      };
      getWithoutImage();
    }
  }, [site]);

  return (
    <Stack direction="row" width="100%" alignItems="center" spacing={2}>
      <Stack direction="column" justifyContent="left" alignItems="left" width="70%">
        <H5>{site.url.replace('https://', '').replace('/wp-json', '')}</H5>
        {site.lastStatUpdate && (
          <SubTitle>Last updated at {new Date(site.lastStatUpdate).toLocaleString()}</SubTitle>
        )}
      </Stack>

      {without_image == null && <CircularProgress size={15} />}
      {without_image != null && (
        <Span
          style={{
            color: 'rgba(110, 169, 240)',
            fontWeight: 'bolder',
            padding: '2px 6px',
            borderRadius: '4px',
            background: 'rgba(110, 169, 240)',
            color: 'white',
            border: '1px solid rgba(110, 169, 240)'
          }}
        >
          {without_image}
        </Span>
      )}

      {/* {!without_image != null && <Span>post(s) has no image in</Span>} */}
    </Stack>
  );
};

const StatCards2 = () => {
  const { palette } = useTheme();
  const textError = palette.error.main;
  const bgError = lighten(palette.error.main, 0.85);
  const { user } = useAuth();

  const [statData, setStatData] = useState({});
  const [sites, setSites] = useState([]);

  useEffect(() => {
    setStatData(getStatData(user));
  }, [user]);

  useEffect(() => {
    const subscribe = onSnapshot(doc(getFirestore(), 'userAccount', user.uid), (doc) => {
      const data = doc.data();
      const user = { acc: data };
      setStatData(getStatData(user));
    });

    const getSites = async () => {
      const sites = [];
      const userSites = await getDocs(
        collection(getFirestore(), 'userData', user.uid, 'wordpress_sites')
      );

      userSites.forEach((doc) => {
        sites.push({ ...doc.data(), id: doc.id });
      });
      setSites(sites);
    };
    getSites();

    return () => subscribe();
  }, []);

  return (
    <Grid container spacing={3} sx={{ mb: 3 }}>
      <Grid item xs={12} md={6}>
        <Card elevation={3} sx={{ p: 2 }}>
          <ContentBox>
            <FabIcon size="medium" sx={{ background: bgError, overflow: 'hidden' }}>
              <Icon sx={{ color: textError }}>hide_image</Icon>
            </FabIcon>
            <Stack direction="column" justifyContent="left" alignItems="left">
              <H3 textcolor={textError}>Posts Without Images</H3>
              <Span textcolor={'grey'} style={{ marginLeft: '12px' }}>
                Your websites may have posts without images in draft or future category
              </Span>
            </Stack>
          </ContentBox>

          <ContentBox
            sx={{ pt: 2 }}
            style={{ marginLeft: '55px', flexDirection: 'column', gap: '5px' }}
          >
            {sites.map((site, i) => (
              <SiteInfo key={i} site={site} />
            ))}
          </ContentBox>
        </Card>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        spacing={3}
      >
        <Card elevation={3} sx={{ p: 2 }}>
          <ContentBox>
            <FabIcon size="medium" sx={{ background: 'rgba(9, 182, 109, 0.15)' }}>
              <Icon sx={{ color: '#08ad6c' }}>image_add</Icon>
            </FabIcon>
            <Stack direction="column" justifyContent="left" alignItems="left">
              <H3 textcolor={'#08ad6c'}>Image Generated</H3>
              <Span textcolor={'grey'} style={{ marginLeft: '12px' }}>
                {!statData.last_image_generated
                  ? 'No Image Generated Yet'
                  : 'Last Generated at ' +
                    new Date(statData.last_image_generated).toLocaleTimeString()}
              </Span>
            </Stack>
          </ContentBox>

          <ContentBox sx={{ pt: 2 }} style={{ marginLeft: '55px' }}>
            <H1>{statData.image_generated}</H1>
            <IconBox sx={{ background: 'rgba(9, 182, 109, 0.45)' }}>
              <Icon className="icon">date_range</Icon>
            </IconBox>
            <Span textcolor={'#08ad6c'}>
              {statData.image_generated_date >= 0
                ? new Date(statData.image_generated_date).toLocaleDateString()
                : 'N/A'}
            </Span>
          </ContentBox>
        </Card>
        <br />
        <Card elevation={3} sx={{ p: 2 }}>
          <ContentBox>
            <FabIcon
              size="medium"
              sx={{ background: 'rgba(110, 169, 240,.15)', overflow: 'hidden' }}
            >
              <Icon sx={{ color: 'rgba(110, 169, 240)' }}>web</Icon>
            </FabIcon>
            <Stack direction="column" justifyContent="left" alignItems="left">
              <H3 textcolor="rgba(110, 169, 240)">Post Processed</H3>
              <Span textcolor={'grey'} style={{ marginLeft: '12px' }}>
                {statData.post_in_queue <= 0
                  ? 'No Post is being Processed'
                  : statData.post_in_queue + ' Posts in Queue'}
              </Span>
            </Stack>
          </ContentBox>

          <ContentBox sx={{ pt: 2 }} style={{ marginLeft: '55px' }}>
            <H1>{statData.post_processed}</H1>
            <IconBox sx={{ background: 'rgba(110, 169, 240, 0.45)' }}>
              <Icon className="icon">date_range</Icon>
            </IconBox>
            <Span textcolor="rgba(110, 169, 240)">
              {statData.post_processed_date > 0
                ? new Date(statData.post_processed_date).toLocaleDateString()
                : 'N/A'}
            </Span>
          </ContentBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StatCards2;
