import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import Loading from 'app/components/MatxLoading';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const APIKeys = ({ site, updateSite, userData, selectedSite }) => {
  const [passwordType, setPasswordType] = React.useState('password');
  const [password, setPassword] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setUserName(site.userName);
    setPassword('');
  }, [site.userName]);
  useEffect(() => {
    let it = setTimeout(() => {
      setPasswordType('password');
    }, 10000);

    return () => {
      clearTimeout(it);
    };
  }, [passwordType]);

  const updateUserSiteCredentials = async () => {
    setLoading(true);
    try {
      const fn = httpsCallable(getFunctions(), 'wordpressRequest');

      const { data } = await fn({
        id: site.id,
        url: site.url,
        userName,
        password,
        command: 'updateUserSiteCredentials'
      });

      toast.success('Wordpress credentials updated successfully');
      setPassword('');
    } catch (e) {
      console.error(e);
      toast.error('Failed to update Wordpress credentials');
    }
    setLoading(false);
  };
  return (
    <Card>
      <Typography variant="h6" sx={{ color: '#1976d2', padding: '12px 16px', fontWeight: 500 }}>
        Generation Credentials
      </Typography>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={selectedSite !== 'default' ? 6 : 12}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">Select OpenAI key</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', maxWidth: '70%' }}>
                All the AI related works for generating content for this site will be done using
                this OpenAI key. You can manage your added keys from{' '}
                <Link style={{ fontWeight: 600, color: blue[700] }} to="/credentials">
                  credentials
                </Link>{' '}
                page.
              </Typography>

              <Select
                value={site.settings.openai_key}
                onChange={(e) => {
                  updateSite({
                    settings: { openai_key: e.target.value }
                  });
                }}
                sx={{ width: '300px', marginTop: 2 }}
              >
                {userData?.openai_keys?.map((key) => (
                  <MenuItem key={key.key} value={key.key}>
                    {key.name}
                  </MenuItem>
                ))}
              </Select>

              {!site.settings.openai_key && (
                <Typography
                  variant="body2"
                  sx={{ color: selectedSite === 'default' ? 'red' : blue[600], marginTop: 1 }}
                >
                  {selectedSite === 'default' ? (
                    'Select a default OpenAI key to proceed'
                  ) : (
                    <>
                      The selected OpenAI key in the{' '}
                      <Link style={{ fontWeight: 600, color: blue[700] }} to="/credentials">
                        credentials page
                      </Link>{' '}
                      will be used for this site
                    </>
                  )}
                </Typography>
              )}
            </Box>

            <Box sx={{ flexGrow: 1, marginTop: '20px' }}>
              <Typography variant="subtitle1">Select discord credential</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Images will be generated using this Discord credential for this site.
              </Typography>

              <Select
                value={site.settings.selected_dc_cred || userData.selected_dc_cred}
                onChange={(e) => {
                  updateSite({
                    settings: { selected_dc_cred: parseInt(e.target.value) }
                  });
                }}
                sx={{ width: '300px', marginTop: 2 }}
              >
                {userData?.dc_creds?.map((key, i) => (
                  <MenuItem key={key.name} value={i}>
                    {key.name}
                  </MenuItem>
                ))}
              </Select>

              {site.settings.selected_dc_cred === -1 && (
                <Typography
                  variant="body2"
                  sx={{ color: selectedSite === 'default' ? 'red' : blue[600], marginTop: 1 }}
                >
                  {selectedSite === 'default' ? (
                    'Select a default discord credential to proceed'
                  ) : (
                    <>
                      The selected discord credential in the{' '}
                      <Link style={{ fontWeight: 600, color: blue[700] }} to="/credentials">
                        credentials page
                      </Link>{' '}
                      will be used for this site
                    </>
                  )}
                </Typography>
              )}
            </Box>
          </Grid>

          {selectedSite !== 'default' && (
            <Grid item xs={12} md={6}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">WordPress Credential</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Your can update the WordPress password here.
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <TextField
                  type={passwordType}
                  onFocus={() => setPasswordType('text')}
                  placeholder="Wordpress Password"
                  sx={{ width: '100%', marginTop: 2 }}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordType('text');
                  }}
                />
                <Alert severity="info" style={{ marginTop: '20px' }}>
                  If you have cloudflare or other security measures enabled for a site, please
                  whitelist our IP address
                  <strong
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText('35.193.8.109');
                      toast.success('IP Copied');
                    }}
                  >
                    {' '}
                    35.193.8.109
                  </strong>{' '}
                  before updating the credentials.
                </Alert>
              </Box>

              <Stack spacing={2} direction="row" sx={{ marginTop: 2 }}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={updateUserSiteCredentials}
                  loading={loading}
                >
                  Update Wordpress Password
                </LoadingButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default APIKeys;
